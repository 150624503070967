export const API_ROOT_URL = process.env.REACT_APP_API_HOST;
export const API_FO_URL = API_ROOT_URL + '/fo';
export const API_BO_URL = API_ROOT_URL + '/bo';
export const GLOBAL_OPERATION_ID = "operationId";
export const GLOBAL_OPERATION_HOSTNAME = "operationHostname";
export const GLOBAL_GIFT_ID = "giftId";
export const GLOBAL_OPERATION_PARTICIPANT_ID = "operationParticipantId";
export const GLOBAL_SHOP_ID = "shopId";
export const GLOBAL_GIFT_ORDER_ID = "giftOrderId";
export const GLOBAL_ADMIN_ID = "adminId";

export const shopUrl = (url: string, id: string): string => url.replace(':' + GLOBAL_GIFT_ID, id);
export const orderUrl = (url: string, id: string): string => url.replace(':' + GLOBAL_GIFT_ORDER_ID, id);

export const HCAPTCHA_VERIFY = "https://hcaptcha.com/siteverify"

export const API_URL = {
  OPERATION_INIT_MINIMAL: "operations/domain/:" + GLOBAL_OPERATION_HOSTNAME + "/minimal",
  OPERATION_INIT: "operations/domain/:" + GLOBAL_OPERATION_HOSTNAME,
  LOGIN: "operations/:" + GLOBAL_OPERATION_ID + "/login",
  LOGIN_WITH_TOKEN: "operations/:" + GLOBAL_OPERATION_ID + "/login-with-token",
  VALIDATE_BASKET: "operations/:" + GLOBAL_OPERATION_ID + "/validate-basket",
  CREATE_SUPPORT_TICKET: "support-tickets",
  REFRESH_TOKEN: "operationparticipants/refresh-token",
  RESET_PASSWORD: "operationparticipants/reset-password",
  UPDATE_OPERATION_PARTICIPANT: "operationparticipants",
  PARTICIPANTS: "participants",
  GET_SHOP: "shops/:" + GLOBAL_SHOP_ID,
  GET_PARTICIPANT: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID,
  GET_PARTICIPANT_ADDRESSES: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID + "/addresses",
  GET_MINIMAL_USER_INFO: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID + '/minimal',
  GET_MINIMAL_ADMIN_INFO: "admin/:" + GLOBAL_ADMIN_ID + '/minimal',
  GET_PARTICIPANT_ORDERS: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID + '/orders',
  GET_PARTICIPANT_LAST_ORDER: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID + '/last-order',
  GET_USER_REWARDS: "operationparticipants/:" + GLOBAL_OPERATION_PARTICIPANT_ID + '/rewards',
  UPDATE_PARTICIPANTS: "fo/participants/",
  POST_PARTICIPANTS: "participants",
  GIFT_ORDERS: "giftorders",
  GET_ORDER: "giftorders/:" + GLOBAL_GIFT_ORDER_ID,
  COOKIES: "cookies",
  ADDRESSES: "addresses",
  VALIDATE_ACCESS_CODE: "operations/:" + GLOBAL_OPERATION_ID + "/access-code-validate",
}

export const PUBLIC_URL = {
  HOME: "/",
  LOGIN: "/connexion",
  FORGET_PASSWORD: "/mot-de-passe-oublie",
  RESET_PASSWORD: "/renouvellement-mot-de-passe",
  UNSUSCRIBE: "/desinscription",
  RULES: "/reglement",
  ORDER_DONE: "/commande/:" + GLOBAL_GIFT_ORDER_ID + "/terminee",
  BASKET: "/panier",
  BASKET_SHIPPING_ADDRESS: "/panier/adresse-livraison",
  BASKET_BILLING_ADDRESS: "/panier/adresse-facturation",
  BASKET_PAYMENT: "/panier/paiement",
  GET_GIFT: "cadeau/:" + GLOBAL_GIFT_ID,
  CONTACT: "/contact",
  ACCOUNT: "/mon-compte",
  FAQ: "/faq",
  CGVU: "/cgvu.pdf",
  PERSONAL_DATA: "/ppdp.pdf",
  UPDATE_ACCOUNT: "/mon-compte/modifier-profil",
  ORDERS: "/mon-compte/mes-commandes",
  ORDER: "/mon-compte/mes-commandes/:" + GLOBAL_GIFT_ORDER_ID,
  POINTS: "/mon-compte/mes-points",
  INFORMATION: "/mon-compte/mes-informations",
  INFORMATION_PROFILE: "/mon-compte/mes-informations#mon-profil",
  INFORMATION_PASSWORD: "/mon-compte/mes-informations#mot-de-passe",
  INFORMATION_SHIPPING_ADDRESS: "/mon-compte/mes-informations#adresse-de-livraison",
  INFORMATION_BILLING_ADDRESS: "/mon-compte/mes-informations#adresse-de-facturation",
  MAINTENANCE_MODE_ON: "maintenance"
}
